.aboutContainer {
    margin-top: 2%;
}

@media only screen and (max-width: 320px) {
    .aboutContainer {
        font-size: 1rem;
    }

    .familyImg {
        width: 90%;
    }
}

@media only screen and (min-width: 320px) and (max-width:780px) {
    .aboutContainer {
        font-size: 1.1rem;
    }

    .familyImg {
        width: 80%;
    }
}

@media only screen and (min-width: 780px) and (max-width: 1024px) {
    .aboutContainer {
        font-size: 1.2rem;
    }

    .familyImg {
        width: 70%;
    }
}


@media only screen and (min-width: 1024px) and (max-width: 1600px) {
    .aboutContainer {
        font-size: 1.3rem;
    }

    .familyImg {
        width: 60%;
    }
}


@media only screen and (min-width: 1600px) {
    .aboutContainer {
        font-size: 1.5rem;
    }

    .familyImg {
        width: 50%;
    }
}