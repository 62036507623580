.app {
    min-height: 100vh;
}

.body-container {
    text-align: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    background-color: #ffffff;
}

.verificationPopup {
    height: fit-content;
    margin-bottom: 5%;
}

.verificationCheck {
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}

.verificationCheck input {
    border: solid 3px #2e2e2e;
}

.verificationCheck input:checked {
    background-color: #2e2e2e;
    border-color: #2e2e2e;
}

.verificationCheck label {
    margin-left: 5%;
}

.confirmationButton {
    background-color: #2e2e2e;
    border: #2e2e2e;
}

.confirmationButton:hover {
    background-color: #b78846;
    border-color: #b78846;
}

.confirmationButton:active {
    background-color: #2e2e2e !important;
}