.productContainer {
    max-width: 100%;
    padding-bottom: 10%;
}


.productCol {
    max-width: 50%;
    margin-top: 2%;
}

.product-image-landscape {
    width: 90%;
    margin-top: 10%;
}

.productName {
    font-size: 2rem;
    text-align: center;
}

.productYear {
    font-size: 1.5rem;
    text-align: center;
}

.additionalInfoHeader {
    text-align: left;
    margin-top: 3%;
}

.productInformation {
    font-size: 1rem;
    max-width: 65%;
    text-align: justify;
}

.accoladeList {
    list-style-type: none;
}

@media only screen and (max-width: 400px) {
    .productName {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 700px) {
    .productImgCol {
        min-width: 80%;
    }

    .product-image-landscape {
        margin-top: 5%;
    }

    .productCol {
        min-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .productInformation {
        max-width: 100%;
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
    .productImgCol {
        min-width: 80%;
    }

    .product-image-landscape {
        margin-top: 5%;
    }

    .productCol {
        min-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .productInformation {
        max-width: 80%;
    }

}



@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .productImgCol {
        min-width: 80%;
    }

    .productCol {
        min-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .product-image-landscape {
        margin-top: 5%;
    }
}