.shop-container {
    background-color: white;
    max-width: 90%;
    margin-top: 0;
    margin-bottom: 5%;

}

.specialsRow {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.cardCol {
    margin-top: 2%;
    align-content: center;
}

.specialcardCol {
    margin-top: 2%;
    align-content: center;
}

.productCards {
    margin-bottom: 2%;
    border: none;
}

.thumbnail {
    position: relative;
}

.moreInfo {
    opacity: 0;
    top: 85%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    background-color: #2e2e2e;
    width: 50%;
    color: white;
    vertical-align: center;
}

.card-img-top {
    opacity: 1;
}

.thumbnail:hover .moreInfo {
    transition-duration: 0.8s;
    opacity: 1;
}

.thumbnail:hover img {
    transition-duration: 0.6s;
    box-shadow: 0px 0px 1em 0px rgb(69, 68, 73);
}

.productHeader {
    height: 40%;
    margin-bottom: 1%;
}

.productDescription {
    height: 30%;
    margin-bottom: 1%;
}

.productPrice {
    height: 15%;
    margin-bottom: 2%;
}

.buttonAddtoCart {
    background-color: #2e2e2e;
    color: #FFFF;
    border-color: #2e2e2e;
}


.buttonValueChange {
    background-color: #2e2e2e;
    color: #FFFF;
    border-color: #2e2e2e;
    padding: 0;
}


.buttonAddtoCart:hover {
    background-color: #b78846;
    border-color: #b78846;
    margin: auto;

}

.buttonValueChange:hover {
    background-color: #b78846;
    border-color: #b78846;
}

.buttonAddtoCart:active {
    background-color: #2e2e2e !important;
}

.buttonValueChange:active {
    background-color: #2e2e2e !important;
}

@media only screen and (max-width: 320px) {
    .shop-container {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        max-width: 100%;
    }

    .cardCol {
        min-width: 100%;
        max-width: 100%;
    }

    .specialsRow {
        min-width: 100%;
        max-width: 100%;
    }

    .specialcardCol {
        align-content: center;
        min-width: 100%;
        max-width: 100%;
    }

    .moreInfo {
        font-size: 1rem;
        height: 2rem;
        line-height: 2rem;
    }

    .card-body {
        height: 35vh;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-subtitle {
        font-size: 0.9rem;
    }

    .productDescription {
        font-size: 0.9rem;
    }

    .productPrice {
        font-size: 0.9rem;
    }


    .buttonAddtoCart {
        font-size: 0.75rem;
    }

    .inCart {
        font-size: 0.9rem;
    }

    .buttonValueChange {
        font-size: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 0.75rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 320px) and (max-width:480px) {
    .shop-container {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        max-width: 100%;
    }

    .cardCol {
        min-width: 100%;
        max-width: 100%;
    }


    .specialsRow {
        max-width: 100%;
        min-width: 100%;
    }

    .specialcardCol {
        align-content: center;
        min-width: 100%;
        max-width: 100%;
    }

    .moreInfo {
        font-size: 1.3rem;
        height: 2.6rem;
        line-height: 2.6rem;
    }

    .card-body {
        height: 30vh;
    }

    .card-title {
        font-size: 1.3rem;
    }

    .card-subtitle {
        font-size: 1.17rem;
    }

    .productDescription {
        font-size: 1.17rem;
    }

    .productPrice {
        font-size: 1.17rem;
    }


    .buttonAddtoCart {
        font-size: 0.97rem;
    }

    .inCart {
        font-size: 1.17rem;
    }

    .buttonValueChange {
        font-size: 0.97rem;
        width: 1.95rem;
        height: 1.95rem;
        line-height: 0.97rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 480px) and (max-width: 600px) {
    .cardCol {
        margin-left: auto;
        margin-right: auto;
        min-width: 80%;
        max-width: 80%;
    }

    .specialsRow {
        max-width: 100%;
        min-width: 100%;
    }

    .specialcardCol {
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        min-width: 80%;
        max-width: 80%;
    }

    .moreInfo {
        font-size: 1.1rem;
        height: 2.2rem;
        line-height: 2.2rem;
    }

    .card-body {
        height: 30vh;
    }

    .card-title {
        font-size: 1.25rem;
    }

    .card-subtitle {
        font-size: 1.13rem;
    }

    .productDescription {
        font-size: 1.13rem;
    }

    .productPrice {
        font-size: 1.13rem;
    }


    .buttonAddtoCart {
        font-size: 0.95rem;
    }

    .inCart {
        font-size: 1.13rem;
    }

    .buttonValueChange {
        font-size: 0.95rem;
        width: 1.95rem;
        height: 1.95rem;
        line-height: 0.95rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 600px) and (max-width: 800px) {
    .cardCol {
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        min-width: 80%;
        max-width: 80%;
    }

    .specialsRow {
        max-width: 100%;
        min-width: 100%;
    }

    .specialcardCol {
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        min-width: 80%;
        max-width: 80%;
    }

    .moreInfo {
        font-size: 1.6rem;
        height: 3.2rem;
        line-height: 3.2rem;
    }

    .card-body {
        height: 30vh;
    }

    .card-title {
        font-size: 1.6rem;
    }

    .card-subtitle {
        font-size: 1.45rem;
    }

    .productDescription {
        font-size: 1.45rem;
    }

    .productPrice {
        font-size: 1.45rem;
    }


    .buttonAddtoCart {
        font-size: 1.2rem;
    }

    .inCart {
        font-size: 1.45rem;
    }

    .buttonValueChange {
        font-size: 1.2rem;
        width: 2.4rem;
        height: 2.4rem;
        line-height: 1.2rem;
        margin-right: 2%;
        margin-left: 2%;
    }
}

@media only screen and (min-width: 800px) and (max-width: 900px) {
    .cardCol {
        min-width: 50%;
        max-width: 50%;
    }

    .specialsRow {
        max-width: 100%;
        min-width: 100%;
    }

    .moreInfo {
        font-size: 1.3rem;
        height: 2.6rem;
        line-height: 2.6rem;
    }

    .card-body {
        height: 25vh;
    }

    .card-title {
        font-size: 1.3rem;
    }

    .card-subtitle {
        font-size: 1.17rem;
    }

    .productDescription {
        font-size: 1.17rem;
    }

    .productPrice {
        font-size: 1.17rem;
    }


    .buttonAddtoCart {
        font-size: 1rem;
    }

    .inCart {
        font-size: 1.17rem;
    }

    .buttonValueChange {
        font-size: 1rem;
        width: 2rem;
        height: 2rem;
        line-height: 1rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .cardCol {
        min-width: 50%;
        max-width: 50%;
    }

    .specialsRow {
        max-width: 100%;
        min-width: 100%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }

    .moreInfo {
        font-size: 1.5rem;
        height: 3rem;
        line-height: 3rem;
    }

    .card-body {
        height: 25vh;
    }

    .card-title {
        font-size: 1.5rem;
    }

    .card-subtitle {
        font-size: 1.2rem;
    }

    .productDescription {
        font-size: 1.3rem;
    }

    .productPrice {
        font-size: 1.3rem;
    }


    .buttonAddtoCart {
        font-size: 1rem;
    }

    .inCart {
        font-size: 1.2rem;
    }

    .buttonValueChange {
        font-size: 1rem;
        width: 2rem;
        height: 2rem;
        line-height: 1rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .cardCol {
        min-width: 33%;
        max-width: 33%;
    }

    .specialsRow {
        max-width: 66%;
        min-width: 66%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }

    .moreInfo {
        font-size: 0.9rem;
        height: 1.8rem;
        line-height: 1.8rem;
    }

    .card-body {
        height: 30vh;
    }

    .card-title {
        font-size: 0.9rem;
    }

    .card-subtitle {
        font-size: 0.8rem;
    }

    .productDescription {
        font-size: 0.8rem;
    }

    .productPrice {
        font-size: 0.8rem;
    }


    .buttonAddtoCart {
        font-size: 0.7rem;
    }

    .inCart {
        font-size: 0.8rem;
    }

    .buttonValueChange {
        font-size: 0.7rem;
        width: 1.4rem;
        height: 1.4rem;
        line-height: 0.7rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .cardCol {
        min-width: 33%;
        max-width: 33%;
    }

    .specialsRow {
        max-width: 66%;
        min-width: 66%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }

    .moreInfo {
        font-size: 1.2rem;
        height: 2.4rem;
        line-height: 2.4rem;
    }

    .card-body {
        height: 30vh;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .card-subtitle {
        font-size: 1.08rem;
    }

    .productDescription {
        font-size: 1.08rem;
    }

    .productPrice {
        font-size: 1.08rem;
    }


    .buttonAddtoCart {
        font-size: 0.9rem;
    }

    .inCart {
        font-size: 1.08rem;
    }

    .buttonValueChange {
        font-size: 0.9rem;
        width: 1.8rem;
        height: 1.8rem;
        line-height: 0.9rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {

    .cardCol {
        min-width: 25%;
        max-width: 25%;
    }

    .specialsRow {
        max-width: 50%;
        min-width: 50%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }


    .moreInfo {
        font-size: 1rem;
        height: 2rem;
        line-height: 2rem;
    }

    .card-body {
        height: 25vh;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-subtitle {
        font-size: 0.9rem;
    }

    .productDescription {
        font-size: 0.9rem;
    }

    .productPrice {
        font-size: 0.9rem;
    }


    .buttonAddtoCart {
        font-size: 0.75rem;
    }

    .inCart {
        font-size: 0.9rem;
    }

    .buttonValueChange {
        font-size: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 0.75rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {

    .cardCol {
        min-width: 25%;
        max-width: 25%;
    }

    .specialsRow {
        max-width: 50%;
        min-width: 50%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }

    .moreInfo {
        font-size: 1.2rem;
        height: 2.4rem;
        line-height: 2.4rem;
    }

    .card-body {
        height: 25vh;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .card-subtitle {
        font-size: 1.08rem;
    }

    .productDescription {
        font-size: 1.08rem;
    }

    .productPrice {
        font-size: 1.08rem;
    }


    .buttonAddtoCart {
        font-size: 0.9rem;
    }

    .inCart {
        font-size: 1.08rem;
    }

    .buttonValueChange {
        font-size: 0.9rem;
        width: 1.8rem;
        height: 1.8rem;
        line-height: 0.9rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 1800px) and (max-width: 1920px) {

    .cardCol {
        min-width: 25%;
        max-width: 25%;
    }

    .specialsRow {
        max-width: 50%;
        min-width: 50%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }

    .moreInfo {
        font-size: 1.4rem;
        height: 3rem;
        line-height: 3rem;
    }

    .card-body {
        height: 25vh;
    }

    .card-title {
        font-size: 1.4rem;
    }

    .card-subtitle {
        font-size: 1.3rem;
    }

    .productDescription {
        font-size: 1.3rem;
    }

    .productPrice {
        font-size: 1.3rem;
    }

    .buttonAddtoCart {
        font-size: 1.05rem;
    }

    .inCart {
        font-size: 1.3rem;
    }

    .buttonValueChange {
        font-size: 1.5rem;
        width: 2.1rem;
        height: 2.1rem;
        line-height: 1.05rem;
        margin-right: 2%;
        margin-left: 2%;
    }

}

@media only screen and (min-width: 1920px) {

    .cardCol {
        min-width: 25%;
        max-width: 25%;
    }

    .specialsRow {
        max-width: 50%;
        min-width: 50%;
    }

    .specialcardCol {
        max-width: 50%;
        min-width: 50%;
    }

    .moreInfo {
        font-size: 1.5rem;
        height: 3rem;
        line-height: 3rem;
    }

    .card-body {
        height: 25vh;
    }

    .card-title {
        font-size: 1.5rem;
    }

    .card-subtitle {
        font-size: 1.35rem;
    }

    .productDescription {
        font-size: 1.35rem;
    }

    .productPrice {
        font-size: 1.35rem;
    }

    .buttonAddtoCart {
        font-size: 1.125rem;
    }

    .inCart {
        font-size: 1.35rem;
    }

    .buttonValueChange {
        font-size: 1.125rem;
        width: 2.25rem;
        height: 2.25rem;
        line-height: 1.125rem;
        margin-right: 2%;
        margin-left: 2%;
    }
}