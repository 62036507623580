.infoCard {
    margin-top: 5%;
    margin-bottom: 5%;
}

.card-header {
    font-size: 2rem;
}

.deliveryDetails {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.deliveryDetails p {
    text-align: left;
}

.form-control {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.form-select {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.checkoutButton {
    background-color: #2e2e2e;
    color: #FFFF;
    border-color: #2e2e2e;
}

.checkoutButton:hover {
    background-color: #b78846;
    border-color: #b78846;
}

.checkoutButton:active {
    background-color: #2e2e2e !important;
}

.paymentContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.paypal-buttons {
    max-width: 80%;
}

.cartCard {
    margin-top: 5%;
    margin-bottom: 5%;
}

@media only screen and (max-width: 800px) {
    .cartCol {
        order: 1;
        min-width: 100%;
    }

    .infoCol {
        order: 2;
        min-width: 100%;
    }

}