@media only screen and (max-width: 320px) {
    .successPage {
        font-size: 1.2rem;
    }

}

@media only screen and (min-width: 320px) and (max-width:780px) {
    .successPage {
        font-size: 1.4rem;
    }

}


@media only screen and (min-width: 780px) and (max-width: 1024px) {
    .successPage {
        font-size: 1.6rem;
    }


}


@media only screen and (min-width: 1024px) and (max-width: 1600px) {
    .successPage {
        font-size: 1.8rem;
    }


}


@media only screen and (min-width: 1600px) {
    .successPage {
        font-size: 2rem;
    }
}

.successContainer {
    min-height: 65vh;
}