.bannerImage {
    margin: auto;
    margin-top: 2.5%;
}

.home-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(241, 243, 245);
}

.banner-flexbox-container {
    margin-left: -12.5%;
    margin-right: -12.5%;
    display: flex;
    flex-direction: row;
}

.img-flexbox-container {
    display: flex;
    flex-direction: row;
    margin-left: -12.5%;
    margin-right: -12.5%;
}


.img-flexbox-container p {
    font-size: 2rem;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10%;
}


.quotes {
    max-width: 80%;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
}

.box {
    position: relative;
    background-color: #2e2e2e;
    width: 60%;
    padding: 2px;
    border: 2px solid #b78846;

    &:before,
    &:after {
        content: "•";
        position: absolute;
        width: 14px;
        height: 14px;
        font-size: 14px;
        color: #b78846;
        border: 2px solid #b78846;
        ;
        line-height: 12px;
        top: 5px;
        text-align: center;
    }

    &:before {
        left: 5px;
    }

    &:after {
        right: 5px;
    }

    .box-inner {
        position: relative;
        border: 2px solid #b78846;
        ;
        padding: 10%;

        &:before,
        &:after {
            content: "•";
            position: absolute;
            width: 14px;
            height: 14px;
            font-size: 14px;
            color: #b78846;
            ;
            border: 2px solid #b78846;
            ;
            line-height: 12px;
            bottom: -2px;
            text-align: center;
        }

        &:before {
            left: -2px;
        }

        &:after {
            right: -2px;
        }
    }
}

.quote {
    color: #e7e0d6;
    margin: auto;
    margin-top: 7%;
    padding: 0%, 5%
}

.author {
    text-align: right;
    font-style: italic;
    color: rgb(207, 184, 184);
    padding-right: 3%;
}

.paragraph-container {
    color: rgb(0, 0, 0);
    margin: auto;
}

.paragraph-container p {
    max-width: 80%;
    margin: auto;
    padding: 3%;
    border-style: double;
    border-color: #2e2e2e;
    background-color: aliceblue;
}

@media only screen and (max-width: 320px) {
    .box {
        width: 90%;
    }

    .quotes {
        max-width: 100%;
    }

    .quote {
        font-size: 0.8rem;

    }

    .author {
        font-size: 0.6rem;
    }


    .lekka1 {
        display: none;
    }

    .valley2 {
        display: none;
    }

    .paragraph-container {
        max-width: 100%;
    }

    .paragraph-container p {
        max-width: 100%;
        padding: 0;
        margin: 0;
        font-size: 0.54rem;
    }
}

@media only screen and (min-width: 320px) and (max-width:480px) {
    .box {
        width: 80%;
    }

    .quotes {
        max-width: 100%;
    }

    .quote {
        font-size: 1rem;

    }

    .author {
        font-size: 0.8rem;
    }


    .lekka1 {
        display: none;
    }

    .valley2 {
        display: none;
    }

    .paragraph-container {
        max-width: 100%;
    }

    .paragraph-container p {
        max-width: 100%;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        font-size: 0.59rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 600px) {
    .box {
        width: 75%;
    }

    .quotes {
        max-width: 100%;
    }

    .quote {
        font-size: 1rem;

    }

    .author {
        font-size: 0.8rem;
    }

    .lekka1 {
        display: none;
    }

    .valley2 {
        display: none;
    }

    .paragraph-container {
        max-width: 100%;
    }

    .paragraph-container p {
        max-width: 100%;
        padding: 2%;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 0.85rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 780px) {
    .quote {
        font-size: 0.8rem;

    }

    .author {
        font-size: 0.6rem;
    }

    .paragraph-container {
        max-width: 90%;
    }

    .paragraph-container p {
        font-size: 0.6rem;
    }
}

@media only screen and (min-width: 780px) and (max-width: 900px) {
    .quote {
        font-size: 1.04rem;

    }

    .author {
        font-size: 0.8rem;
    }


    .paragraph-container p {
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .quote {
        font-size: 1.2rem;

    }

    .author {
        font-size: 0.95rem;
    }



    .paragraph-container p {
        font-size: 0.95rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .quote {
        font-size: 1.3rem;

    }

    .author {
        font-size: 1.1rem;
    }


    .paragraph-container p {
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .quote {
        font-size: 1.7rem;

    }

    .author {
        font-size: 1.3rem;
    }


    .paragraph-container p {
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .quote {
        font-size: 1.9rem;

    }

    .author {
        font-size: 1.5rem;
    }


    .paragraph-container p {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
    .quote {
        font-size: 2.2rem;

    }

    .author {
        font-size: 1.7rem;
    }


    .paragraph-container p {
        font-size: 1.7rem;
    }
}


@media only screen and (min-width: 1800px) and (max-width: 1920px) {
    .quote {
        font-size: 2.3rem;

    }

    .author {
        font-size: 1.8rem;
    }


    .paragraph-container p {
        font-size: 1.8rem;
    }
}

@media only screen and (min-width: 1920px) {
    .quote {
        font-size: 2.6rem;

    }

    .author {
        font-size: 2rem;
    }


    .paragraph-container p {
        font-size: 2rem;
    }
}