.footerMain {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2e2e2e;

}

.footerContainer {
    padding-top: 1%;
    padding-bottom: 1%;
    max-width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
}

.footerInfo {
    width: 33.33%;
    text-align: left
}


@media only screen and (max-width: 320px) {
    .footerContainer {
        display: flex;
        flex-direction: column;
        font-size: 0.7rem;
    }

    .footerInfo {
        width: 90%;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }

    .footerContainer h1 {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 320px) and (max-width:455px) {
    .footerContainer {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
    }

    .footerInfo {
        width: 80%;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }

    .footerContainer h1 {
        font-size: 1.8rem;
    }

}

@media only screen and (min-width: 455px) and (max-width:570px) {
    .footerContainer {
        font-size: 0.5rem;
        margin-left: 0;
    }

    .footerContainer h1 {
        font-size: 1.1rem;
    }
}


@media only screen and (min-width: 570px) and (max-width:650px) {
    .footerContainer {
        font-size: 0.6rem;
        margin-left: 0;
    }

    .footerContainer h1 {
        font-size: 1.4rem;
    }
}

@media only screen and (min-width: 650px) and (max-width:770px) {
    .footerContainer {
        font-size: 0.7rem;
        margin-left: 0;
    }

    .footerContainer h1 {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 770px) and (max-width:900px) {
    .footerContainer {
        font-size: 0.9rem;
        margin-left: 1%;
    }

    .footerContainer h1 {
        font-size: 1.9rem;
    }

    .footerInfo p {
        padding-right: 5%;
    }
}

@media only screen and (min-width: 900px) and (max-width:1150px) {
    .footerContainer {
        font-size: 1rem;
        margin-left: 1%;
    }

    .footerContainer h1 {
        font-size: 2.2rem;
    }

    .footerInfo p {
        padding-right: 10%;
    }
}

@media only screen and (min-width: 1150px) and (max-width:1250px) {
    .footerContainer {
        font-size: 1rem;
    }

    .footerContainer h1 {
        font-size: 2.2rem;
    }

    .footerInfo p {
        padding-right: 20%;
    }
}

@media only screen and (min-width: 1250px) and (max-width: 1350px) {
    .footerContainer {
        font-size: 1.1rem;
    }

    .footerContainer h1 {
        font-size: 2.3rem;
    }

    .footerInfo p {
        padding-right: 20%;
    }
}


@media only screen and (min-width: 1350px) and (max-width: 1500px) {
    .footerContainer {
        font-size: 1.2rem;
    }

    .footerContainer h1 {
        font-size: 2.4rem;
    }

    .footerInfo p {
        padding-right: 30%;
    }
}


@media only screen and (min-width: 1500px) and (max-width: 1800px) {
    .footerContainer {
        font-size: 1.2rem;
    }

    .footerContainer {
        margin-left: 7%;
    }

    .footerInfo p {
        padding-right: 30%;
    }
}


@media only screen and (min-width: 1800px) {
    .footerContainer {
        font-size: 1.2rem;
    }

    .footerContainer {
        margin-left: 8%;
    }

    .footerInfo p {
        padding-right: 40%;
    }
}