.navbar {
    background-color: #2e2e2e;
    padding-left: 20%;
    padding-right: 25%;
}

.navbar-brand {
    color: white;
}

.navbar-brand:hover {
    color: #b78846;
}

.navbar-collapse {
    flex-direction: row-reverse;
    text-align: left;
}

.nav-link {
    color: white;
    margin-right: 10%;
    margin-left: 10%;
}

.nav-link:hover {
    color: #b78846;
}

.cartButton {
    text-align: left;
    min-width: 6rem;
    background-color: #2e2e2e;
    border: #2e2e2e;
    margin-right: 10%;
    margin-left: 10%;
}

.cartButton:hover {
    background-color: #2e2e2e;
    color: #b78846;
}

.cartButton:active {
    background-color: #2e2e2e !important;
    color: #b78846 !important;
}

.cartAmount {
    vertical-align: top;
    background-color: #b78846;
    color: #FFFF;
    font-size: 1.2rem;
    border-radius: 1rem;
    padding: 0 0.3rem;
}

@media only screen and (max-width: 320px) {

    .navbar {
        padding-left: 5%;
        padding-right: 5%;
    }

    .navbar-brand {
        font-size: 1.4rem;
    }

    .nav-link {
        margin-left: 0;
        font-size: 1.2rem;
    }

    .cartButton {
        margin-left: 0;
        padding-left: 0;
        font-size: 1.8rem;
    }

    .cartAmount {
        font-size: 1.2rem;
    }

    .navbar-toggler {
        font-size: 0.8rem;
    }

    .termsDiv {
        font-size: 0.8rem;
    }

    .closeButton {
        font-size: 0.5rem;
    }

}

@media only screen and (min-width: 320px) and (max-width:480px) {
    .navbar {
        padding-left: 10%;
        padding-right: 10%;
    }

    .navbar-brand {
        font-size: 1.4rem;
    }

    .nav-link {
        margin-left: 0;
        font-size: 1.2rem;
    }

    .navbar-toggler {
        font-size: 0.8rem;
    }

    .cartButton {
        margin-left: 0;
        padding-left: 0;
        font-size: 1.8rem;
    }

    .cartAmount {
        font-size: 1.2rem;
    }

    .termsDiv {
        font-size: 1rem;
    }

    .closeButton {
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 600px) {
    .navbar {
        padding-left: 15%;
        padding-right: 15%;
    }

    .navbar-brand {
        font-size: 1.6rem;
    }

    .nav-link {
        margin-left: 0;
        font-size: 1.4rem;
    }

    .cartButton {
        margin-left: 0;
        padding-left: 0;
        font-size: 1.8rem;
    }

    .cartAmount {
        font-size: 1.2rem;
    }

    .termsDiv {
        font-size: 1.3rem;
    }

    .closeButton {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .navbar {
        padding-left: 15%;
        padding-right: 15%;
    }

    .navbar-brand {
        font-size: 1.6rem;
    }

    .nav-link {
        margin-left: 0;
        font-size: 1.4rem;
    }

    .cartButton {
        margin-left: 0;
        padding-left: 0;
        font-size: 1.8rem;
    }

    .cartAmount {
        font-size: 1.2rem;
    }

    .termsDiv {
        font-size: 1.4rem;
    }

    .closeButton {
        font-size: 1.1rem;
    }
}


@media only screen and (min-width: 770px) and (max-width: 1025px) {
    .navbar {
        padding-left: 15%;
        padding-right: 20%;
    }

    .navbar-brand {
        font-size: 1.8rem;
    }

    .nav-link {
        font-size: 1.6rem;
    }

    .navbar-toggler {
        font-size: 1.1rem;
    }

    .cartButton {
        font-size: 1.6rem;
    }

    .cartAmount {
        font-size: 1.5
    }

    .termsDiv {
        font-size: 1.5rem;
    }

    .closeButton {
        font-size: 1.2rem;
    }

}

@media only screen and (min-width: 1026px) and (max-width: 1199px) {
    .navbar-brand {
        font-size: 2rem;

    }

    .nav-link {
        font-size: 1.8rem;
    }

    .cartButton {
        font-size: 1.8rem;
    }

    .cartAmount {
        font-size: 1.2
    }

    .termsDiv {
        font-size: 1.8rem;
    }

    .closeButton {
        font-size: 1.2rem;
    }


}

@media only screen and (min-width: 1200px) {
    .navbar-brand {
        font-size: 2.2rem;

    }

    .nav-link {
        font-size: 2rem;
    }

    .cartButton {
        font-size: 2rem;
    }

    .cartAmount {
        font-size: 1.5
    }

    .termsDiv {
        font-size: 2rem;
    }

    .closeButton {
        font-size: 1.5rem;
    }
}

.cartModal {
    font-size: 1.2rem;
}

.cartItemsValueButton {
    background-color: #2e2e2e;
    color: #FFFF;
    border-color: #2e2e2e;
    margin-left: 2%;
    margin-right: 2%;
}

.cartItemsValueButton:hover {
    background-color: #b78846;
    border-color: #b78846;
}

.cartItemsValueButton:active {
    background-color: #2e2e2e !important;
}

.cartItemsRemoveButton {
    background-color: #2e2e2e;
    color: #FFFF;
    border-color: #2e2e2e;
}

.cartItemsRemoveButton:hover {
    background-color: #b78846;
    border-color: #b78846;
}

.cartItemsRemoveButton:active {
    background-color: #2e2e2e !important;
}

.cartItemsCheckoutButton {
    background-color: #b78846;
    border-color: #b78846;
    color: #2e2e2e;
}

.cartItemsCheckoutButton:hover {
    background-color: #b78846;
    border-color: #2e2e2e;
    color: #FFFF
}

.cartItemsCheckoutButton:active {
    background-color: #2e2e2e !important;
}

.termsDiv {
    width: 100%;
    background-color: #b78846;
    min-height: fit-content;
}

.closeButton {
    float: right;
    color: #2e2e2e;
    background-color: #b78846;
    border-color: #b78846;
    margin-top: 15%;
    line-height: 0;
    max-width: 50%;
}

.closeButton:hover {
    background-color: #b78846;
    border-color: #b78846;
}

.closeButton:active {
    background-color: #b78846 !important;
    border-color: #b78846 !important;
}